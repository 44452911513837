@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --bg-primary: #ffffff;
  --bg-secondary: #f8fafc;
  --header-height: 64px; /* Adjust based on your actual header height */
  /* ... other variables will be set by ThemeProvider */
}
body {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Dark theme popup styles */
.map-dark .leaflet-popup-content-wrapper,
.map-dark .leaflet-popup-tip {
  background: transparent;
  box-shadow: none;
  color: white;
}

.map-dark .leaflet-popup-content {
  margin: 0;
  min-width: 300px;
}

.map-dark .leaflet-popup-tip {
  background: #1a1f2c;
}

.map-dark .leaflet-container a.leaflet-popup-close-button {
  color: #94a3b8;
  padding: 8px;
  width: 28px;
  height: 28px;
}

/* Light theme popup styles */
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #1a1f2c;
  padding: 0;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.leaflet-popup-content {
  margin: 0;
  min-width: 300px;
}

.leaflet-popup-tip {
  background: white;
}

.map-dark .leaflet-container a.leaflet-popup-close-button:hover {
  color: white;
}

.map-dark .leaflet-popup-content p {
  margin: 0;
}

/* Set a lower z-index for the map container */
.leaflet-container {
  z-index: 1;
}

/* Set lower z-index for map controls */
.leaflet-control-container {
  z-index: 2;
}

/* Set lower z-index for map popups */
.leaflet-popup {
  z-index: 3;
}

/* Ensure modals stay on top */
.modal-overlay {
  z-index: 50;
}

/* Add this class to both HowItWorksModal and PhotoModal */
.modal-backdrop {
  z-index: 50 !important;
}

/* Ensure the map's parent container has a defined z-index */
.map-container {
  position: relative;
  z-index: 1;
}

/* Modal backdrops */
.backdrop-blur-sm {
  backdrop-filter: blur(10px);
}

/* Menu backgrounds */
.bg-bg-primary\/95,
.bg-bg-secondary\/95,
.bg-bg-tertiary\/95,
.bg-white\/95,
.bg-\[\#1a1f2c\]\/95 {
  backdrop-filter: blur(10px);
}

/* Theme-aware transitions */
.transition-theme {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

/* Theme-aware animations */
@keyframes highlight-pulse {
  0%, 100% {
    box-shadow: 0 0 0 0 var(--accent-primary);
  }
  50% {
    box-shadow: 0 0 0 4px var(--accent-primary-light);
  }
}

.highlight-pulse {
  animation: highlight-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes sparkle {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.95);
  }
}

.animate-sparkle {
  animation: sparkle 2s ease-in-out infinite;
}

/* Utilities*/
@layer utilities {
  .sticky-quick-match {
    position: sticky;
    top: 0;
    z-index: 40;
    background: linear-gradient(
      to bottom,
      var(--bg-primary) 0%,
      var(--bg-primary) 80%,
      transparent 100%
    );
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .animate-fadeIn {
    animation: fadeIn 0.3s ease-out forwards;
  }
}

@keyframes shimmer {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.5;
  }
}

.animate-shimmer {
  animation: shimmer 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}